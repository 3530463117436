/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { navigate } from 'gatsby';
import Style from './join-offer-card.module.scss';
import decorLeaf from '../../assets/images/decor-leaf.png';
import decorTropical from '../../assets/images/decor-tropical.png';
import formatMoney from '../../util/formatMoney';
import { GET_COUPON } from '../../graphql/coupon/schema';
import GET_USER from './data';
import allowedVendors from '../../util/vendors';

const JoinOfferCard = ({ plan, productMetadata, showTrialButton, disable }) => {
  const {
    id: planId,
    amount,
    nickname,
    trial_period_days,
    metadata: {
      associated_coupons,
      subtitle: planSubtitle,
      button_label: planButtonLabel
    }
  } = plan;
  const { button_label } = productMetadata;
  const {
    data: { Coupon }
  } = useQuery(GET_COUPON);
  const { data: me } = useQuery(GET_USER);

  const {
    amount_off,
    percent_off,
    coupon,
    metadata: {
      button_label: coupon_button_label,
      subtitle: coupon_subtitle,
      coupon_trial_period
    }
  } = Coupon;

  const normalizedAssociatedCoupons = associated_coupons.map((c) =>
    c.toLowerCase()
  );
  // check if this plan can use this coupon
  const discountedPlan = normalizedAssociatedCoupons.includes(coupon);

  let COUPON = null;
  let couponSubtitle = null;
  let couponButtonLabel = null;

  // if this plan allows this coupon, find the type of discount
  if (discountedPlan) {
    couponSubtitle = coupon_subtitle;
    couponButtonLabel = coupon_button_label;
    if (amount_off) {
      COUPON = 'amount_off';
    }
    if (percent_off) {
      COUPON = 'percent_off';
    }
  }
  // are there any COUPON?
  const amountType = COUPON ? 'discount' : 'normal';

  // calculate the amounts based on the discount type if any
  const CALCULATE_AMOUNT = {
    amount_off: amount - amount_off,
    percent_off: amount - amount * percent_off,
    null: amount
  };

  // amount calc for the monthly amount
  const DISPLAY_MONTHLY_AMOUNT = {
    Yearly: CALCULATE_AMOUNT[COUPON] / 12,
    Monthly: CALCULATE_AMOUNT[COUPON],
    Quarterly: CALCULATE_AMOUNT[COUPON] / 3
  };

  // amount calc for the daily amount
  const DISPLAY_DAILY_AMOUNT = {
    Yearly: CALCULATE_AMOUNT[COUPON] / 365,
    Monthly: CALCULATE_AMOUNT[COUPON] / 30,
    Quarterly: CALCULATE_AMOUNT[COUPON] / 90
  };

  const CALC_NORMAL_MONTHLY_AMOUNT = {
    Yearly: amount / 12,
    Monthly: amount,
    Quarterly: amount / 3
  };

  // is total amount discounted or not?
  const DISPLAY_TOTAL_AMOUNT = {
    normal: amount,
    discount: CALCULATE_AMOUNT[COUPON]
  };

  const CALC_TYPE_SAVINGS = {
    amount_off: (amount_off / amount) * 100,
    percent_off: percent_off * 100
  };

  // friendly nickname text
  const BILLED = {
    Yearly: 'billed once a year',
    Monthly: 'billed monthly',
    Quarterly: 'billed quarterly'
  };

  const DECOR = {
    Yearly: <img alt="" src={decorLeaf} className={Style.decorLeaf} />,
    Monthly: <img alt="" src={decorTropical} className={Style.decorTropical} />
  };

  const handleClick = () => {
    const isLoggedIn = localStorage.getItem('tiu_token');
    const { vendor } = me.getUserProfile.checkAppPremiumUser;
    const { active } = me.getUserProfile.checkWebPremiumUser;
    const isSubscribed =
      active || allowedVendors.includes(vendor && vendor.toLowerCase());

    localStorage.setItem('selected_subscription', planId);

    // if you are not logged and don't have a subscription.
    if (!isSubscribed && !isLoggedIn) {
      navigate('/sign-up/');
    }

    // if you are logged in and not web subscribed
    if (isLoggedIn && !isSubscribed) {
      navigate(`/secure/profile/payment/`);
    }

    // if you are logged in and have a web subscription
    if (isLoggedIn && isSubscribed) {
      navigate('/secure/profile/manage');
    }
  };

  const crossedPrice = () => (
    <span className="text-blush-tone-4" style={{ bottom: '1rem' }}>
      <span style={{ textDecoration: 'line-through' }}>
        {`
        ${formatMoney(CALC_NORMAL_MONTHLY_AMOUNT[nickname])} / month
      `}
      </span>
      <span style={{ fontStyle: 'italic' }}>
        {`- Save ${Math.round(CALC_TYPE_SAVINGS[COUPON])}%!`}
      </span>
    </span>
  );

  const centsPerDay = `Join for $0.${Math.round(
    DISPLAY_DAILY_AMOUNT[nickname]
  )} per day`;

  return (
    <div className={`inner-slide ${Style.innerSlide}`}>
      {DECOR[nickname]}
      <div className={Style.copyContainer}>
        <h3 className={Style.heading}>
          {nickname}
          <span className="text-blush-tone-4">
            {planSubtitle || couponSubtitle || centsPerDay}
          </span>
        </h3>

        <div className={Style.price}>
          {discountedPlan && crossedPrice()}
          <h1>
            {formatMoney(DISPLAY_MONTHLY_AMOUNT[nickname])}
            <span className={Style.month}>/ month</span>
          </h1>
        </div>

        <p>
          {coupon_trial_period
            ? `${coupon_trial_period}-day free trial`
            : trial_period_days
            ? `${trial_period_days}-day free trial`
            : ''}
          <br />
          {`${formatMoney(
            DISPLAY_TOTAL_AMOUNT[amountType]
          )} / ${nickname.toLowerCase()} (${BILLED[nickname]}) `}
        </p>
      </div>
      <div className="fade-up-in-100 bg-transparent">
        {showTrialButton && (
          <button
            type="button"
            className="button primary expanded"
            disabled={disable}
            data-test="button-signup"
            onClick={() => handleClick()}
          >
            {couponButtonLabel || planButtonLabel || button_label}
          </button>
        )}
      </div>
      <small style={{ fontSize: '10px' }}>*Conditions Apply</small>
    </div>
  );
};

JoinOfferCard.propTypes = {
  plan: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object
  ]).isRequired,
  productMetadata: PropTypes.objectOf(PropTypes.string).isRequired,
  showTrialButton: PropTypes.bool.isRequired,
  disable: PropTypes.bool.isRequired
};

export default JoinOfferCard;
