import { gql } from 'apollo-boost';

export const GET_COUPON = gql`
  query GetCoupon {
    Coupon @client {
      amount_off
      coupon_applied
      coupon
      duration
      duration_in_months
      id
      metadata {
        additional_terms
        button_label
        coupon_trial_period
        price_detail
        promotion_accepted
        subtitle
      }
      percent_off
    }
  }
`;

export const CLEAR_COUPON = gql`
  mutation clearCoupon {
    clearCoupon @client
  }
`;

export const SAVE_COUPON = gql`
  mutation SaveCoupon(
    $amount_off: Float
    $coupon: String
    $coupon_applied: Boolean
    $duration: String
    $duration_in_months: Int
    $metadata: JSON
    $percent_off: Float
  ) {
    saveCoupon(
      amount_off: $amount_off
      coupon: $coupon
      coupon_applied: $coupon_applied
      duration: $duration
      duration_in_months: $duration_in_months
      metadata: $metadata
      percent_off: $percent_off
    ) @client
  }
`;

export const APPLY_STRIPE_COUPON = gql`
  mutation ApplyStripeCoupon($coupon: String!) {
    applyStripeCoupon(coupon: $coupon) {
      id
      associated_plans
      duration
      duration_in_months
      amount_off
      percent_off
      valid
      metadata {
        additional_terms
        button_label
        coupon_trial_period
        price_detail
        promotion_accepted
        subtitle
      }
      responses {
        path
        status
        message
      }
    }
  }
`;
