import gql from 'graphql-tag';

const GET_USER = gql`
  query GetUser {
    getUserProfile {
      id
      checkAppPremiumUser {
        vendor
      }
      checkWebPremiumUser {
        active
      }
    }
  }
`;

export default GET_USER;
