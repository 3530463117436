import { gql } from 'apollo-boost';

const STRIPE_PLANS_QUERY = gql`
  query RetrieveStripePlans {
    retrieveStripeProduct {
      data {
        id
        metadata {
          subtitle
          terms_title
          terms_trial
          terms
          terms2
          terms3
          terms4
          button_label
          promotion_accepted
        }
      }
    }

    retrieveStripePlans {
      data {
        id
        amount
        nickname
        currency
        interval
        interval_count
        trial_period_days
        metadata {
          subtitle
          button_label
          studio_product_id
          associated_coupons
          associated_coupons2
          associated_coupons3
          associated_coupons4
          pricing_period
        }
      }
    }
  }
`;

export default STRIPE_PLANS_QUERY;
